import React, { useState } from "react";
import Footer from "./components/Footer.js";
import Data from "./components/Data.js";
import NavBar from "./components/NavBar.js";
import Home from "./components/Home.js";
import Categories from "./components/Categories.js";
import Admin from './components/Admin.js';
import TempLog from "./components/TempLog.js";
import { Route, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import FindRegistry from "./components/FindRegistry.js";
import ComingSoon from "./components/ComingSoon.js";

const api = process.env.REACT_APP_API || "http://localhost:8000/";
toast.configure({
  autoClose: 5000,
});

const App = () => {
  const [loggedIn, setLoggedIn] = useState(true);

  return (
    <ComingSoon />
  );
  // return (
  //   <div style={{ marginLeft: '20px' }}>
  //     {loggedIn ? (
  //       <div>
  //         <NavBar />



  //         <Route exact path="/" render={() => <Home />} />


        
  //         <Route  path="/guest/findRegistry" render={() =>  <FindRegistry />} />

  //         <Route  path="/host/registry" render={() =>  <FindRegistry />} />
  //         <Route  path="/host/invite" render={() =>  <FindRegistry />} />

  //         <Route  path="/vendor" render={() =>  <FindRegistry />} />






        
        
  //         <Route
  //           path="/vendor/inventory/add"
  //           render={props => <Data {...props} api={api} />}
  //         />
  //           <Route  path="/registry" render={() =>  <FindRegistry />} />
      
        
  //         <Route
  //           exact
  //           path="/bonnzie/admin"
  //           render={props => <Admin {...props} api={api} />}
  //         />

      
  //         <Footer />
  //       </div>
  //     ) : (
  //       <TempLog />
  //     )}
  //   </div>
  // );
}

export default App;
