import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import Hex_Red from '../assets/HomePage/Hexagon_Red.svg'
import Hex_LB from '../assets/HomePage/Hexagon_Light_Brown.svg'
import Cir_Mint from '../assets/HomePage/Hollow_Circle_Mint.svg'
import ZZ_Blue from '../assets/HomePage/ZZ_Blue.svg'
import Rec_Red from '../assets/HomePage/Rectangle_Red.svg'
import Tri_Yel from '../assets/HomePage/Slim_Triangle_Yellow.svg'
import Tri_Yel_Up from '../assets/HomePage/Triangle_Yellow_Up.svg'
import Square_Green from '../assets/HomePage/Square_Green.svg'
// import Square_Peach from '../assets/HomePage/Square_Light_Peach.svg'
import swiggle_y from '../assets/HomePage/Swiggle_Yellow.svg'
import shape_1 from '../assets/HomePage/1.svg'
import shape_2 from '../assets/HomePage/2.svg'
import shape_3 from '../assets/HomePage/3.svg'
import shape_4 from '../assets/HomePage/4.svg'
import temp from "../assets/tempExample.png";
import FindRegistry from './FindRegistry.js'
import SignUp from './SignUp.js'
import Confetti from "react-confetti";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shapes: [shape_1, shape_2, shape_3, shape_4],
      height: 0,
      showConfetti: false, // State to control confetti visibility
    };
  }

  componentDidMount() {
    const height = this.divElement.clientHeight;
    this.setState({ height });

    // Trigger confetti for 3 seconds on page load
    this.setState({ showConfetti: true });
    setTimeout(() => this.setState({ showConfetti: false }), 3000);
  }

  generateDots = () => {
    const { shapes } = this.state;
    let arrayShape = [];
    for (let i = 0; i < 200; i++) {
      let randomIndex = shapes[Math.floor(Math.random() * shapes.length)];
      let randAnimate = Math.floor(Math.random() * 6) + 1;
      arrayShape.push(
        <img
          alt="dot"
          style={{
            top: Math.floor(Math.random() * this.state.height),
            left: Math.floor(Math.random() * window.innerWidth),
          }}
          className={`dots dot-animate${randAnimate}`}
          src={randomIndex}
        />
      );
    }
    return arrayShape;
  };

  render() {
    const { showConfetti } = this.state;

    return (
      <div className="home-contain" ref={(divElement) => (this.divElement = divElement)}>
        {/* Confetti component */}
        {showConfetti && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
          />
        )}

        {/* Existing content */}
        <section className="home-top-section index2">
          <h1 className="headers hero-msg">GET THE GIFTS<br /> YOUR KIDS NEED</h1>

       
          <FindRegistry />

          <div className="swiggle-contain">
            <img alt="large swiggle" className="swiggle-line swigg1" src={swiggle_y} />
          </div>
        </section>

        <section className="options-contain">
          <div className="options-sub-contain toy">
            <img alt="shape" className="shapes index2 toy-anime-1" src={Hex_Red} />
            <img alt="shape" className="shapes index1 toy-anime-2" src={Square_Green} />
            <Link className="option-link" to="categories/toys">
              <div className="options opt-toy">TOYS</div>
            </Link>
          </div>
          <div className="options-sub-contain fund">
                  <img alt="tri-yellow" className="shapes index2 fund-anime-1" src={Tri_Yel} />
                  <img alt="rec-red" className="shapes index1 fund-anime-2" src={Rec_Red} />
                  <Link className="option-link" to="categories/funds" ><div className="options opt-fund">FUNDS</div></Link>
                </div>

                <div className="options-sub-contain essential">
                  <img alt="zz-blue" className="shapes index2 ess-anime-1" src={ZZ_Blue} />
                  <div className="shapes index1 ess-anime-2"></div>
                  <Link className="option-link" to="categories/essential" ><div className="options opt-ess">ESSENTIAL</div></Link>
                </div>

                <div className="options-sub-contain class">
                  <img alt="tri-yellow-up" className="shapes index2 class-anime-1" src={Tri_Yel_Up} />
                  <div className="shapes index1 class-anime-2"></div>
                  <Link className="option-link" to="categories/classes" ><div className="options opt-class">CLASSES</div></Link>
                </div>

                <div className="options-sub-contain game">
                  <img alt="hex-lb" className="shapes index1 game-anime-1" src={Hex_LB} />
                  <img alt="cir-mint" className="shapes index2 game-anime-2" src={Cir_Mint} />
                  <Link className="option-link" to="categories/games" ><div className="options opt-game">GAMES</div></Link>
                </div>

                <div className="options-sub-contain donate">
                  <img alt="hex-red" className="shapes index2 don-anime-1" src={Hex_Red} />
                  <img alt="square-green" className="shapes index1 don-anime-2" src={Square_Green} />
                  <Link className="option-link" to="categories/donate" ><div className="options opt-donate">DONATE</div></Link>
                </div>
        </section>

        <div className="divide"></div>

        <section className="example">
          <div className="example-left">
            <img alt="hex-lb" className="examp-1" src={Hex_LB} />
            <img alt="cir-mint" className="examp-2" src={Cir_Mint} />
            <div className="example-circle examp-3"></div>
            <img alt="fake example" className="example-temp" src={temp} />
          </div>
          <div className="example-right">
            <Button className="invite-button">CUSTOMIZE YOUR INVITE</Button>
            <h3 className="invite-text">
              CHOOSE FROM OVER 30 <br /> DIFFERENT DESIGN AND MAKE IT YOURS
            </h3>
          </div>
        </section>

        <div className="swiggle-contain-2">
          <img alt="large swiggle" className="swiggle-line swigg2" src={swiggle_y} />
        </div>

        <section className="sample-display"></section>
      </div>
    );
  }
}

export default Home;
