import React from 'react';
import  './styles/ComingSoon.css';
import  ComingSoonJPG from '../assets/coming_soon.jpg';

const ComingSoon = () => {
    return (
   <>
   <img src={ComingSoonJPG}  className='coming-soon-jpg' alt="Coming Soon" />
   </>
    );
};

export default ComingSoon;